import {
    /*IoWarningOutline,
    IoPartlySunnyOutline,
    IoMapOutline,
    IoScaleOutline,*/
    IoCalendarOutline,
    IoBarChartOutline,
    IoLibraryOutline,
    IoRibbonOutline,
    IoDocumentOutline,
    IoHomeOutline,
} from 'react-icons/io5';
// import { FiSettings } from 'react-icons/fi';

const routesConfig = [
    {
        id: 'home',
        title: 'Inicio',
        messageId: 'sidebar.home',
        type: 'item',
        icon: <IoHomeOutline />,
        url: '/dashboards/metrics',
    },
    /*{
        id: 'risk-management',
        title: 'Gestión de riesgo',
        messageId: 'sidebar.risk.management',
        type: 'collapse',
        icon: <IoWarningOutline />,
        children: [
            {
                id: 'pipeline-risk-analysis',
                title: 'Análisis de riesgo de ductos',
                messageId: 'sidebar.pipeline.risk.analysis',
                type: 'item',
                url: '/risk-management/pipeline-risk-analysis',
            },
            {
                id: 'risk-based-inspection',
                title: 'Análisis de riesgo de ductos',
                messageId: 'sidebar.risk.based.inspection',
                type: 'item',
                url: '/risk-management/risk-based-inspection',
            },
            {
                id: 'criticality-analysis',
                title: 'Análisis de criticidad',
                messageId: 'sidebar.criticality.analysis',
                type: 'item',
                url: '/risk-management/criticality-analysis',
            },
        ],
    },*/
    {
        id: 'information-management',
        title: 'Admón. de la información',
        messageId: 'sidebar.information.management',
        type: 'collapse',
        icon: <IoLibraryOutline />,
        children: [
            {
                id: 'asset-hierarchy',
                title: 'Jerarquía de activos',
                messageId: 'sidebar.asset.hierarchy',
                type: 'item',
                url: '/information-management/hierarchy/list',
            },
            {
                id: 'consult',
                title: 'Consulta',
                messageId: 'sidebar.consult',
                type: 'item',
                url: '/information-management/consult',
            },
        ],
    },
    {
        id: 'integrity-assessment',
        title: 'Evaluación de integridad',
        messageId: 'sidebar.integrity.assessment',
        type: 'collapse',
        icon: <IoDocumentOutline />,
        children: [
            {
                id: 'assessment',
                title: 'Modulo de evaluación',
                messageId: 'sidebar.assessment',
                type: 'item',
                url: '/integrity-assessesment/assessment',
            },
            {
                id: 'records',
                title: 'Registros de evaluación',
                messageId: 'sidebar.list.assessment',
                type: 'item',
                url: '/integrity-assessesment/list/assessment',
            },
        ],
    },
    {
        id: 'activity-management',
        title: 'Gestión de actividades',
        messageId: 'sidebar.activity.management',
        type: 'collapse',
        icon: <IoCalendarOutline />,
        children: [
            {
                id: 'schemes',
                title: 'Ordenes',
                messageId: 'sidebar.activity.management.order.list',
                type: 'item',
                url: '/activity-management/orders/list/',
            },
        ],
    },
    {
        id: 'performance-evaluation',
        title: 'Evaluación del desempeño',
        messageId: 'sidebar.performance.evaluation',
        type: 'collapse',
        icon: <IoBarChartOutline />,
        children: [
            /*{
                id: 'dashboard',
                title: 'Tablero',
                messageId: 'sidebar.performance.evaluation.dashboard',
                type: 'item',
                url: '/performance-evaluation/dashboard',
            },*/
            {
                id: 'schemes',
                title: 'Tablero',
                messageId: 'sidebar.performance.evaluation.schemes',
                type: 'item',
                url: '/performance-evaluation/schemes/',
            },
            {
                id: 'kpis',
                title: 'KPIs',
                messageId: 'sidebar.performance.evaluation.kpis',
                type: 'item',
                url: '/performance-evaluation/kpis',
            },
        ],
    },
    /*{
        id: 'corrosion-evaluation',
        title: 'Evaluación de corrosión',
        messageId: 'sidebar.corrosion.evaluation',
        type: 'collapse',
        icon: <IoScaleOutline />,
        children: [
            {
                id: 'internal',
                title: 'Corrosión interna',
                messageId: 'sidebar.corrosion.evaluation.internal',
                type: 'item',
                url: '/corrosion-evaluation/internal',
            },
            {
                id: 'external',
                title: 'Corrosión externa',
                messageId: 'sidebar.corrosion.evaluation.external',
                type: 'item',
                url: '/corrosion-evaluation/external',
            },
        ],
    },*/
    /*{
        id: 'demage-mechanism',
        title: 'Mecanismo de daño',
        messageId: 'sidebar.demage.mechanism',
        type: 'item',
        icon: <IoPartlySunnyOutline />,
        url: '/demage-mechanism/evaluation',
    },
    {
        id: 'gis',
        title: 'GIS',
        messageId: 'sidebar.gis',
        type: 'item',
        icon: <IoMapOutline />,
        url: '/gis/maps',
    },*/
    {
        id: 'regulatory-compliance',
        title: 'Cumplimiento regulatorio',
        messageId: 'sidebar.regulatory.compliance',
        type: 'collapse',
        icon: <IoRibbonOutline />,
        children: [
            {
                id: 'nom-004',
                title: 'NOM-004',
                messageId: 'sidebar.regulatory.compliance.nom004',
                type: 'item',
                url: '/regulatory-compliance/nom-004',
            },
            {
                id: 'nom-007',
                title: 'NOM-007',
                messageId: 'sidebar.regulatory.compliance.nom007',
                type: 'item',
                url: '/regulatory-compliance/nom-007',
            },
            {
                id: 'nom-009',
                title: 'NOM-009',
                messageId: 'sidebar.regulatory.compliance.nom009',
                type: 'item',
                url: '/regulatory-compliance/nom-009',
            },
            {
                id: 'nom-020',
                title: 'NOM-020',
                messageId: 'sidebar.regulatory.compliance.nom020',
                type: 'item',
                url: '/regulatory-complianc,e/nom-020',
            },
            {
                id: 'contrato',
                title: 'Contrato',
                messageId: 'sidebar.regulatory.compliance.contrato',
                type: 'item',
                url: '',
            },
        ],
    },
    // {
    //     id: 'administration',
    //     title: 'Administracion',
    //     messageId: 'sidebar.administration',
    //     type: 'collapse',
    //     icon: <FiSettings />,
    //     children: [
    //         {
    //             id: 'site',
    //             title: 'Sitios',
    //             messageId: 'sidebar.administration.catalogs.site',
    //             type: 'item',
    //             url: '/administration/catalogs/sites',
    //         },
    //         {
    //             id: 'plants',
    //             title: 'Plantas',
    //             messageId: 'sidebar.administration.catalogs.plant',
    //             type: 'item',
    //             url: '/administration/catalogs/plants',
    //         },
    //         {
    //             id: 'systems',
    //             title: 'Sistemas',
    //             messageId: 'sidebar.administration.catalogs.system',
    //             type: 'item',
    //             url: '/administration/catalogs/systems',
    //         },
    //     ],
    // },
];
export default routesConfig;
