import React from 'react';

const OrderList = React.lazy(() => import('./orders/List'));
const OrderListImpacto = React.lazy(() => import('./ordersImpacto/Activities'));

export const activityManagementConfig = [
    {
        path: '/activity-management/orders/list',
        element: <OrderList />,
    },
    {
        path: '/activity-management/orders/activities/list',
        element: <OrderListImpacto />,
    },
];
